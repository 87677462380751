.header {
    display: flex;
    align-items: center;
}

.header-logo {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 70px;
}

.header-menu {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 70px;
}


.header-item {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 70px;
}











.card-name {
    font-size: 13.5px;
    font-weight: 800;
}

.card-location {
    font-size: 15px;
    font-weight: 600;
}

.card-date {
    font-size: 14px;
    font-weight: 600;
}




.carousel-container {
    position: relative;
}

.carousel-next-button {
    position: absolute;
    right: -500px;
    background-color: #fff;
    height: 50px;
    width: 50px;
    z-index: 1040;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    visibility: hidden;
    opacity: 0;
    display: none;
    transition: all 0.2s ease-in-out;
}

.carousel-previous-button {
    position: absolute;
    left: -500px;
    background-color: #fff;
    height: 50px;
    width: 50px;
    z-index: 1040;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    visibility: hidden;
    opacity: 0;
    display: none;
    transition: all 0.2s ease-in-out;
}

.carousel-container:hover .carousel-previous-button {
    left: 5px;
    visibility: visible;
    opacity: 1;
    display: flex;
    transition: all 0.2s ease-in-out;
}

.carousel-container:hover .carousel-next-button {
    right: 5px;
    visibility: visible;
    opacity: 1;
    display: flex;
    transition: all 0.2s ease-in-out;
}







.section-title {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
}


.footer {
    height: auto;
    background-color: #03031a;
}


.footer-menu-title {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-align: center;
}

.footer-menu-separetor {
    background-color: #bc037f;
    content: "";
    display: block;
    height: 1px;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 80px;
}

.footer-menu-item {
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
}

.btn-kompass {
    align-items: center;
    background-color: #2b3289;
    border-color: #2b3289 !important;
    border-radius: 0;
    display: flex;
    justify-content: center;
    padding: 0 12px;
}

.btn-kompass:hover {
    background-color: #1f2465;
    border-color: #1f2465 !important;
}





.upper-button {
    height: 50px;
    width: 50px;
    background-color: #2b3289;
    border-radius: 100%;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1050;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-kompass {
    background-color: #2b3289;
    color: #fff !important;
}

.bg-kompass>i {
    background-color: #2b3289;
    color: #fff !important;
}

.bg-kompass:hover {
    background-color: #0e135f !important;
    color: #fff !important;
}

.bg-kompass:hover>i {
    background-color: #0e135f !important;
    color: #fff !important;
}

.background-galery {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.background-sponsor {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}


@media (min-width:300px) {
    .carousel-image {
        height: 300px;
        background-position: calc(50% - 88px) 0px;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
    }

    .carousel-next-button {
        top: 40%;
        height: 35px;
        width: 35px;
    }

    .carousel-previous-button {
        top: 40%;
        height: 35px;
        width: 35px;
    }



    .carousel-title {
        color: #fff;
        display: flex;
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;
    }

    .carousel-info {
        color: #fff;
        display: flex;
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
    }

    .carousel-location {
        color: #fff;
        display: flex;
        font-size: 14px;
        font-weight: 600;
    }

    .carousel-date {
        color: #fff;
        display: flex;
        font-size: 14px;
        font-weight: 600;
    }

    .carousel-time {
        color: #fff;
        display: flex;
        font-size: 14px;
        font-weight: 600;
    }

    .carousel-button {
        width: 100%;
        display: flex;
    }

    .card-image {
        height: 380px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .about-image {
        height: 550px !important;
        width: 100%;
        border-radius: 12px;
    }

    .banner-height {
        height: 150px !important;
        background-position: bottom;
    }

    .about-image {
        height: auto !important;
        width: 100%;
        border-radius: 12px;
    }

    .background-biografy {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 500px !important;
        margin: 0 !important;
        margin-bottom: 30px !important;
    }


    .background-fag {
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 250px !important;
    }
}

@media (min-width:576px) {
    .carousel-image {
        height: 350px;
        background-position: calc(50% - 40px) 0px;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
    }

    .carousel-next-button {
        top: 40%;
        height: 35px;
        width: 35px;
    }

    .carousel-previous-button {
        top: 40%;
        height: 35px;
        width: 35px;
    }



    .carousel-title {
        color: #fff;
        display: flex;
        font-size: 32px;
        line-height: 36px;
        font-weight: 700;
    }

    .carousel-info {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 400;
    }

    .carousel-location {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }

    .carousel-date {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }

    .carousel-time {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }

    .carousel-button {
        width: 80%;
        display: flex;
    }

    .card-image {
        height: 380px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .about-image {
        height: auto !important;
        width: 100%;
        border-radius: 12px;
    }

    .background-biografy {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 500px !important;
        margin: 0 !important;
    }

    .banner-height {
        height: 200px !important;
        background-position: bottom;
    }

    .background-fag {
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 250px !important;
    }
}

@media (min-width:768px) {
    .carousel-image {
        height: 450px;
        background-position: calc(50% - 50px) 0px;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
    }

    .carousel-next-button {
        top: 40%;
        height: 45px;
        width: 45px;
    }

    .carousel-previous-button {
        top: 40%;
        height: 45px;
        width: 45px;
    }


    .carousel-title {
        color: #fff;
        display: flex;
        font-size: 42px;
        line-height: 42px;

        font-weight: 700;
    }

    .carousel-info {
        color: #fff;
        display: flex;
        font-size: 20px;
        font-weight: 400;
    }

    .carousel-location {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }

    .carousel-date {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }

    .carousel-time {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }

    .carousel-button {
        width: 70%;
        display: flex;
    }

    .card-image {
        height: 380px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }


    .about-image {
        height: auto !important;
        width: 100%;
        border-radius: 12px;
    }

    .background-biografy {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 500px !important;
    }

    .banner-height {
        height: 200px !important;
        background-position: bottom;
    }

    
    .background-fag {
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 320px !important;
    }
}

@media (min-width:992px) {
    .carousel-image {
        height: 500px;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
    }

    .carousel-next-button {
        top: 40%;
        height: 45px;
        width: 45px;
    }

    .carousel-previous-button {
        top: 40%;
        height: 45px;
        width: 45px;
    }


    .carousel-title {
        color: #fff;
        display: flex;
        font-size: 40px;
        line-height: 40px;
        font-weight: 700;
    }

    .carousel-info {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 400;
    }

    .carousel-location {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }

    .carousel-date {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }

    .carousel-time {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }

    .carousel-button {
        width: 95%;
        display: flex;
    }


    .about-image {
        height: auto !important;
        width: 100%;
        border-radius: 12px;
    }

    .background-biografy {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 100% !important;
    }

    .banner-height {
        height: 250px !important;
        background-position: bottom;
    }

    .background-fag {
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 360px !important;
    }
}

@media (min-width:1200px) {
    .carousel-image {
        height: 550px;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
    }

    .carousel-next-button {
        top: 40%;
        height: 45px;
        width: 45px;
    }

    .carousel-previous-button {
        top: 40%;
        height: 45px;
        width: 45px;
    }


    .carousel-title {
        color: #fff;
        display: flex;
        font-size: 40px;
        line-height: 40px;
        font-weight: 700;
    }

    .carousel-info {
        color: #fff;
        display: flex;
        font-size: 20px;
        font-weight: 400;
    }

    .carousel-location {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }

    .carousel-date {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }

    .carousel-time {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }

    .carousel-button {
        width: 85%;
        display: flex;
    }

    .about-image {
        height: auto !important;
        width: 100%;
        border-radius: 12px;
    }

    .background-biografy {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 100% !important;
    }

    .banner-height {
        height: 250px !important;
        background-position: bottom;
    }

    .background-fag {
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 450px !important;
    }
}

@media (min-width:1400px) {
    .carousel-image {
        height: 750px;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
    }


    .carousel-next-button {
        top: 40%;
        height: 45px;
        width: 45px;
    }

    .carousel-previous-button {
        top: 40%;
        height: 45px;
        width: 45px;
    }

    .carousel-title {
        color: #fff;
        display: flex;
        font-size: 44px;
        line-height: 46px;
        font-weight: 700;
    }

    .carousel-info {
        color: #fff;
        display: flex;
        font-size: 20px;
        font-weight: 400;
    }

    .carousel-location {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }

    .carousel-date {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }

    .carousel-time {
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }

    .carousel-button {
        width: 100%;
        display: flex;
    }

    .card-image {
        height: 280px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .about-image {
        height: auto !important;
        width: 100%;
        border-radius: 12px;
    }

    .background-biografy {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 500px !important;
    }


    .banner-height {
        height: 250px !important;
        background-position: bottom;
    }

    .background-fag {
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 450px !important;
    }
}
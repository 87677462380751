.no-scrool {
    height: 100%;
    overflow-y: scroll;
    padding: 10px;
}

.h-100vh {
    height: 100vh;
}

.no-scrool::-webkit-scrollbar {
    width: 0px;
}

.no-scrool {
    scrollbar-width: none;
}

.no-scrool {
    -ms-overflow-style: none;
}




/*
#  PIARTS SELECT
*/
.select-modal {
    position: absolute;
    width: 100%;
    z-index: 900;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.select-modal.show {
    visibility: visible;
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.card-item {
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-weight: 500;
    border-bottom: solid 1px #ededed;

}

.card-item:hover {
    background-color: #e5e5e5;
}

.card-item.active {
    background-color: #efefef;
}

.card-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 800;
    visibility: hidden;
    opacity: 0;
}

.card-overlay.show {
    visibility: visible;
    opacity: 1;
}


.page-loader.in {
    visibility: visible;
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.page-loader.out {
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.page-content.out {
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.page-content.in {
    visibility: visible;
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.background-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.background-contain {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}









.scene {
    position: relative;
    z-index: 2;
    height: 80px;
    width: 80px;
    display: grid;
    place-items: center;
}

.cube-wrapper {
    transform-style: preserve-3d;
    animation: bouncing 2s infinite;
}

.cube {
    transform-style: preserve-3d;
    transform: rotateX(45deg) rotateZ(45deg);
    animation: rotation 2s infinite;
}






.cube-faces {
    transform-style: preserve-3d;
    height: 30px;
    width: 30px;
    position: relative;
    transform-origin: 0 0;
    transform: translateX(0) translateY(0) translateZ(-40px);
}

.cube-face {
    position: absolute;
    inset: 0;
    background: #f8285a;
    border: solid 1px #8e0b29;
}

.cube-face.shadow {
    transform: translateZ(-30px);
    animation: bouncing-shadow 2s infinite;
}

.cube-face.top {
    transform: translateZ(30px);
}

.cube-face.front {
    transform-origin: 0 50%;
    transform: rotateY(-90deg);
}

.cube-face.back {
    transform-origin: 0 50%;
    transform: rotateY(-90deg) translateZ(-30px);
}

.cube-face.right {
    transform-origin: 50% 0;
    transform: rotateX(-90deg) translateY(-30px);
}

.cube-face.left {
    transform-origin: 50% 0;
    transform: rotateX(-90deg) translateY(-30px) translateZ(30px);
}

@keyframes rotation {
    0% {
        transform: rotateX(45deg) rotateY(0) rotateZ(45deg);
        animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
    }

    50% {
        transform: rotateX(45deg) rotateY(0) rotateZ(225deg);
        animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    }

    100% {
        transform: rotateX(45deg) rotateY(0) rotateZ(405deg);
        animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
    }
}

@keyframes bouncing {
    0% {
        transform: translateY(-5px);
        animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    }

    45% {
        transform: translateY(20px);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    100% {
        transform: translateY(-5px);
        animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    }
}

@keyframes bouncing-shadow {
    0% {
        transform: translateZ(-30px) scale(1.3);
        animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
        opacity: 0.05;
    }

    45% {
        transform: translateZ(0);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        opacity: 0.3;
    }

    100% {
        transform: translateZ(-30px) scale(1.3);
        animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
        opacity: 0.05;
    }
}


















.scene-logo {
    position: relative;
    z-index: 2;
    height: 30px;
    width: 70px;
    display: grid;
    place-items: center;
}

.cube-logo-wrapper {
    transform-style: preserve-3d;
    animation: bouncings 2s infinite;
}

.cube-logo {
    transform-style: preserve-3d;
    transform: rotateX(45deg) rotateZ(45deg);
    animation: rotations 2s infinite;
}

.cube-logo-faces {
    transform-style: preserve-3d;
    height: 30px;
    width: 30px;
    position: relative;
    transform-origin: 0 0;
    transform: translateX(0) translateY(0) translateZ(-20px);
}

.cube-logo-logo-face {
    position: absolute;
    inset: 0;
    background: #f8285a;
    border: solid 1px #8e0b29;
}


.cube-logo-face.top {
    transform: translateZ(30px);
}

.cube-logo-face.front {
    transform-origin: 0 50%;
    transform: rotateY(-90deg);
}

.cube-logo-face.back {
    transform-origin: 0 50%;
    transform: rotateY(-90deg) translateZ(-30px);
}

.cube-logo-face.right {
    transform-origin: 50% 0;
    transform: rotateX(-90deg) translateY(-30px);
}

.cube-logo-face.left {
    transform-origin: 50% 0;
    transform: rotateX(-90deg) translateY(-30px) translateZ(30px);
}


/*
@keyframes rotations {
    0% {
        transform: rotateX(45deg) rotateY(0) rotateZ(45deg);
        animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
    }

    50% {
        transform: rotateX(45deg) rotateY(0) rotateZ(225deg);
        animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    }

    100% {
        transform: rotateX(45deg) rotateY(0) rotateZ(405deg);
        animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
    }
}

@keyframes bouncings {
    0% {
        transform: translateY(-5px);
        animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    }

    45% {
        transform: translateY(20px);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    100% {
        transform: translateY(-5px);
        animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    }
}

@keyframes bouncing-shadows {
    0% {
        transform: translateZ(-30px) scale(1.3);
        animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
        opacity: 0.05;
    }

    45% {
        transform: translateZ(0);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        opacity: 0.3;
    }

    100% {
        transform: translateZ(-30px) scale(1.3);
        animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
        opacity: 0.05;
    }
}
*/


.scene-sm {
    position: relative;
    z-index: 2;
    height: 20px;
    width: 50px;
    display: grid;
    place-items: center;
}

.cube-wrapper-sm {
    transform-style: preserve-3d;
    animation: bouncing-sm 2s infinite;
}

.cube-sm {
    transform-style: preserve-3d;
    transform: rotateX(45deg) rotateZ(45deg);
    animation: rotation-sm 2s infinite;
}



.cube-face-sm.shadow {
    transform: translateZ(-30px);
    animation: bouncing-shadow-sm 2s infinite;
}

.cube-faces-sm {
    transform-style: preserve-3d;
    height: 22px;
    width: 22px;
    position: relative;
    transform-origin: 0 0;
    transform: translateX(0) translateY(0) translateZ(-10px);
}

.cube-face-sm {
    position: absolute;
    inset: 0;
    background: #ffffff;
    border: solid 1px #071437;
}



.cube-face-sm.top {
    transform: translateZ(22px);
}

.cube-face-sm.front {
    transform-origin: 0 50%;
    transform: rotateY(-90deg);
}

.cube-face-sm.back {
    transform-origin: 0 50%;
    transform: rotateY(-90deg) translateY(0px) translateZ(-22px);
}

.cube-face-sm.right {
    transform-origin: 50% 0;
    transform: rotateX(-90deg) translateY(-22px);
}

.cube-face-sm.left {
    transform-origin: 50% 0;
    transform: rotateX(-90deg) translateY(-22px) translateZ(22px);
}


@keyframes rotation-sm {
    0% {
        transform: rotateX(45deg) rotateY(0) rotateZ(45deg);
        animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
    }

    50% {
        transform: rotateX(45deg) rotateY(0) rotateZ(225deg);
        animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    }

    100% {
        transform: rotateX(45deg) rotateY(0) rotateZ(405deg);
        animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
    }
}

@keyframes bouncing-sm {
    0% {
        transform: translateY(-5px);
        animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    }

    45% {
        transform: translateY(5px);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    100% {
        transform: translateY(-5px);
        animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    }
}

@keyframes bouncing-shadow-sm {
    0% {
        transform: translateZ(-20px) scale(1.3);
        animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
        opacity: 0.05;
    }

    45% {
        transform: translateZ(0);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        opacity: 0.3;
    }

    100% {
        transform: translateZ(-20px) scale(1.3);
        animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
        opacity: 0.05;
    }
}

.poppover {
    position: relative;
}

.poppover-message {
    display: none;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: 1050;
    width: 100%;
}

.poppover-icon {
    display: none;
    position: absolute;
    top: 7px;
    z-index: 1060;
    left: 7px;
}

.poppover:hover .poppover-icon {
    display: block;
    position: absolute;
}

.poppover:hover .poppover-message {
    display: inline-block;
}